import LandingPage from "../Components/Home/LandingPage/LandingPage";
import InstitutionCards from "../Components/Home/InstitutionsCards/InstitutionCards";
import Courses from "../Components/Home/Courses/Courses";
import FeaturedCollege from "../Components/Home/FeaturedCollege/FeaturedCollege";
import FilteringInstitutions from "../Components/Home/FilteringInstitutions/FilteringInstitutions";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Vidyauni | Home</title>
      </Helmet>
      <LandingPage />
      <InstitutionCards />
      {/* <EntranceExamH /> */}
      <Courses />
      {/* <FilteringInstitutions /> */}
      <FeaturedCollege />
      {/* <Articles /> */}
      <div className="m-0 d-flex justify-content-center">
        <p className="m-0 mb-1">
          <b>Developed By:</b>
          <a
            href="https://svsrco.com"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none"
          >
            &nbsp;&nbsp;SVSR Communications Pvt Ltd
          </a>
        </p>
      </div>
    </>
  );
}

export default Home;
