import { useState } from "react";
import "./courses.css";
import { FaFlask } from "react-icons/fa";
import {
  GiMedicines,
  GiDna1,
  GiPlantRoots,
  GiTestTubes,
  GiChemicalDrop,
  GiMicroscope,
  GiDiploma,
  GiMicroscopeLens,
  GiPill,
  GiFactory,
} from "react-icons/gi";
import {
  FaTools,
  FaChartLine,
  FaBalanceScale,
  FaEye,
  FaMicroscope,
} from "react-icons/fa";

function Courses() {
  const [cardName, setCardName] = useState([
    {
      coursename: "Pharmacology",
      icon: <GiMedicines />,
    },
    {
      coursename: "Pharmaceutical Chemistry",
      icon: <FaFlask />,
    },
    {
      coursename: "Pharmaceutical Biotechnology",
      icon: <GiDna1 />,
    },
    {
      coursename: "Pharmacognosy",
      icon: <GiPlantRoots />,
    },
    {
      coursename: "Pharmaceutical Analysis",
      icon: <GiTestTubes />,
    },
    {
      coursename: "Pharmaceutical Engineering",
      icon: <FaTools />,
    },
    {
      coursename: "Pharmaceutical Formulation",
      icon: <GiChemicalDrop />,
    },
    {
      coursename: "Pharmaceutical Management",
      icon: <GiDiploma />,
    },
    {
      coursename: "Pharmaceutical Technology",
      icon: <GiMicroscope />,
    },
    {
      coursename: "Clinical Pharmacy",
      icon: <GiMedicines />,
    },
    {
      coursename: "Pharmaceutical Microbiology",
      icon: <GiMicroscopeLens />,
    },
    {
      coursename: "Pharmacoeconomics",
      icon: <FaChartLine />,
    },
    {
      coursename: "Pharmaceutical Marketing",
      icon: <FaBalanceScale />,
    },

    {
      coursename: "Pharmaceutical Quality Assurance",
      icon: <GiTestTubes />,
    },
    {
      coursename: "Pharmaceutical Production",
      icon: <GiFactory />,
    },
    {
      coursename: "Pharmaceutical Analysis",
      icon: <GiTestTubes />,
    },
    {
      coursename: "Pharmacovigilance",
      icon: <FaEye />,
    },
    {
      coursename: "Pharmaceutical Research",
      icon: <FaMicroscope />,
    },
    {
      coursename: "Pharmaceutical Technology",
      icon: <GiChemicalDrop />,
    },
    {
      coursename: "Industrial Pharmacy",
      icon: <GiChemicalDrop />,
    },
    {
      coursename: "Clinical Research",
      icon: <GiPill />,
    },
    {
      coursename: "Drug Regulatory Affairs",
      icon: <FaBalanceScale />,
    },
    {
      coursename: "Pharmaceutical Sciences",
      icon: <GiMicroscope />,
    },
    {
      coursename: "Pharmaceutical Manufacturing",
      icon: <GiFactory />,
    },
  ]);

  return (
    <div className="coursesPage my-4">
      <h2 className="my-3">Departments</h2>

      <div className="d-flex justify-content-center  flex-wrap wr_courseCard mx-auto">
        {cardName.map((course, index) => (
          <div
            key={index}
            className="courseCard rounded-2 d-flex p-4 justify-content-start align-items-center ps-4"
          >
            {course.icon}
            <h6 className="ps-3">{course.coursename} </h6>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Courses;
