import { NavLink, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import AddInstitute from "../Components/Add Insititute/AddInstitute";
import "./navbar.css";
import { AuthContext } from "../App";
import { useState, useContext, useEffect } from "react";

function Navlinks({ handleNavClick }) {
  const [show, setShow] = useState(false);
  const [admin, setAdmin] = useState(false);
  const { credentials, handleLogout } = useContext(AuthContext);

  const handleClick = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (credentials) {
      if (credentials.token && credentials.role === "admin") {
        setAdmin(true);
      }
    }
  }, []);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="md" className="px-auto">
        <AddInstitute handleClose={handleClose} />
      </Modal>
      <nav className="justify-content-between d-flex flex-column flex-lg-row  align-items-center nav-links">
        <NavLink to="/" onClick={handleNavClick} className="px-3 py-2">
          Home
        </NavLink>
        <NavLink
          to="/institutions"
          onClick={handleNavClick}
          className="px-3 py-2"
        >
          Institutions
        </NavLink>
        {/* <NavLink to="/blogs" onClick={handleNavClick} className="px-3 py-2">
          Articles
        </NavLink> */}
        {/* <NavLink
          to="/universities"
          onClick={handleNavClick}
          className="px-3 py-2"
        >
          Universities
        </NavLink> */}
        {/* <NavLink
          to="/organizations"
          onClick={handleNavClick}
          className="px-3 py-2"
        >
          MBA
        </NavLink> */}
        {credentials.token && (
          <Link to="/" onClick={handleLogout} className="px-3 py-2">
            Logout
          </Link>
        )}
        {credentials.token && (
          <button className="addUniversityBtn" onClick={handleClick}>
            Add Institute
          </button>
        )}
      </nav>
    </>
  );
}

export default Navlinks;
