import React, { useState } from "react";
import Navlinks from "./NavLinks";
import "./navbar.css";
import menu from "../Assets/burgerMenu.png";
import { Button, Offcanvas } from "react-bootstrap";
import { RxHamburgerMenu } from "react-icons/rx";

function Navbar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="navbar py-1 px-3">
      <a href="/" className="text-decoration-none logo fw-bold">
        VidyaUni
      </a>

      {/* Mobile view components */}
      <div className="d-flex d-lg-none justify-content-end">
        <RxHamburgerMenu onClick={handleShow} className="fs-1" />

        {/* Offcanvas for mobile view */}
        <div className="d-lg-none ">
          <Offcanvas
            show={show}
            onHide={handleClose}
            responsive="lg"
            placement="start"
            className="offCanvasNavbar"
          >
            <div>
              <button
                className="btn-close position-absolute top-0 end-0 m-3"
                onClick={handleClose}
              ></button>
              <Offcanvas.Header className="mobileMenu">
                <Offcanvas.Title>
                  <Navlinks handleNavClick={handleClose} />
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                <p className="mb-0"></p>
              </Offcanvas.Body>
            </div>
          </Offcanvas>
        </div>
      </div>

      {/* Desktop view components */}
      <div className="d-none d-lg-block ">
        <Navlinks />
      </div>
    </div>
  );
}

export default Navbar;
