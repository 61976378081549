import notFoundImg from "../../Assets/error.png";
import { Helmet } from "react-helmet";
import "./404Page.css";
function NotFoundPage() {
  return (
    <div className="d-flex flex-column flex-md-row align-items-center justify-content-md-center pageNotFoundWrap p-5 bg-white">
      <Helmet>
        <title> Vidyauni | 404 not found</title>
      </Helmet>
      <img src={notFoundImg} alt="page not found" />

      <div>
        <p className="text-center">
          Sorry, the page you're searching for seems to have taken a break.
        </p>
        <p className="text-center">
          Go to <a href="/">Home</a>
        </p>
      </div>
    </div>
  );
}

export default NotFoundPage;
