import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Helmet } from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../Boot/config.json";
import "./instituteInfoPage.css";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { GoArrowLeft } from "react-icons/go";
import test from "../../Assets/homePageBackground.jpg";
import defaultLogo from "../../Assets/default image.webp";
import { FaPencilAlt, FaPlus, FaPhoneAlt } from "react-icons/fa";
import { FaArrowLeft, FaMoneyBills, FaCircleInfo } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
import { CgWebsite } from "react-icons/cg";
import {
  MdEmail,
  MdDelete,
  MdContentCopy,
  MdEdit,
  MdMenuBook,
} from "react-icons/md";
import { GrCircleInformation } from "react-icons/gr";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { CiClock2 } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { GiConfirmed } from "react-icons/gi";
import { RxCrossCircled } from "react-icons/rx";
import { BiSolidCategory } from "react-icons/bi";
import { RiTimeFill, RiNotification2Fill } from "react-icons/ri";
import { PiExamFill } from "react-icons/pi";

import { AuthContext } from "../../App";
import { LuPlus } from "react-icons/lu";

function InstituteInfoPage() {
  const { instituteName, id } = useParams();

  const [uni, setUni] = useState({});
  const [show, setShow] = useState(false);
  const [cShow, setcShow] = useState(false);
  const [editCourse, setEditCourse] = useState(false);
  const [ilShow, setIlShow] = useState(false);
  const [coursePageShow, setCoursePageshow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [editNameMode, setEditNameMode] = useState(false);
  const [editPhone, setEditPhone] = useState("");
  const [editName, seteditName] = useState("");
  const [error, setError] = useState("");
  const [editedLogo, setEditedLogo] = useState(null);
  const [editedBanner, setEditedBanner] = useState(null);
  const [editLogoStat, setEditLogoStat] = useState(false);
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editWebsiteUrl, setWebsiteUrl] = useState("");
  const [imageType, setImageType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [addDept, setAddDept] = useState(false);
  const [dept, setDept] = useState([]);
  const [addEligibility, setAddEligibility] = useState(false);
  const [eligibityExams, setEligibilityExams] = useState([]);
  const [eligibilityValue, setEligibilityValue] = useState("");
  const [instituteDepartments, setInstituteDepartments] = useState([]);

  const [submitState, setSubmitState] = useState(false);
  const [delStatus, setDelStatus] = useState(false);
  const [courseData, setCourseData] = useState({
    department: "",
    courseName: "",
    courseDurationYears: "",
    courseDurationMonths: "",
    feeCurrency: "",
    courseFee: "",
    courseFeeType: "",
    eligibility: [],
    eligibilityAdditionalInfo: "",
    aboutCourse: "",
  });
  const [editCourseData, setEditCourseData] = useState({});
  const [seo, setSeo] = useState({
    slug: "",
    title: "",
    metaDescription: "",
    keywords: "",
  });
  const [seoSubmit, setSeoSubmit] = useState(false);
  const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const { credentials } = useContext(AuthContext);

  const [coursesInfo, setCoursesInfo] = useState([]);

  const editor = useRef(null);
  const nameEditor = useRef(null);
  const detailsEditor = useRef(null);

  const Url = `${config.baseUrl.slice(0, -5)}`;

  // Function to handle opening the modal and setting initial data
  const handleAddContent = (e) => {
    setEditData({
      aboutInstitute: uni.aboutInstitute,
    });
    setShow(true);
  };

  const handleAddCourse = (e) => {
    setEditCourse(false);
    setcShow(true);
  };

  const handleCourseChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "addDepartment") {
      setCourseData((prev) => ({ ...prev, department: value }));
    } else if (name === "eligibility") {
      if (checked) {
        setCourseData((prev) => ({
          ...prev,
          eligibility: [...prev.eligibility, value],
        }));
      } else {
        setCourseData((prev) => ({
          ...prev,
          eligibility: prev.eligibility.filter((el) => el !== value),
        }));
      }
    } else if (name === "addEligibilty") {
      setCourseData((prev) => ({
        ...prev,
        eligibility: [...prev.eligibility, value],
      }));
    } else {
      setCourseData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleCoursePage = (id) => {
    axios
      .get(`${config.baseUrl}/Universities/getCourseInfo/${id}`)
      .then((res) => {
        const data = res.data;

        setCourseData({
          department: data.department,
          courseName: data.courseName,
          courseDurationYears: data.courseDurationYears || 0,
          courseDurationMonths: data.courseDurationMonths || 0,
          feeCurrency: data.feeCurrency || "",
          courseFee: data.courseFee || "",
          courseFeeType: data.courseFeeType || "",
          eligibility: data.eligibility,
          eligibilityAdditionalInfo: data.eligibilityAdditionalInfo || "",
          aboutCourse: data.aboutCourse,
        });
      });
    setCoursePageshow(true);
  };

  //to edit a course
  const handleEditCourse = (id) => {
    // Make the API call to fetch course data
    axios
      .get(`${config.baseUrl}/Universities/getCourseInfo/${id}`)
      .then((res) => {
        const data = res.data;

        // Set state for edit course data and form fields
        setEditCourseData(data);
        setCourseData({
          department: data.department,
          courseName: data.courseName,
          courseDurationYears: data.courseDurationYears || "",
          courseDurationMonths: data.courseDurationMonths || "",
          feeCurrency: data.feeCurrency || "",
          courseFee: data.courseFee || "",
          courseFeeType: data.courseFeeType || "",
          eligibility: data.eligibility,
          eligibilityAdditionalInfo: data.eligibilityAdditionalInfo || "",
          aboutCourse: data.aboutCourse,
        });

        // Set edit course flag and show modal
        setEditCourse(true);
        setcShow(true);
      })
      .catch((err) => {
        console.error("Error fetching course data:", err);
      });
  };

  //to delete a course
  const handleDeleteCourse = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          title: "custom-swal-title",
          text: "custom-swal-text",
          confirmButton: "custom-swal-button",
          cancelButton: "custom-swal-cancel-button",
        },
      });

      if (result.isConfirmed) {
        setDelStatus(true);
        await axios
          .delete(`${config.baseUrl}/Universities/deleteCourse/${id}`, {
            headers: {
              Authorization: credentials.token,
            },
          })
          .then((res) => {
            setDelStatus(false);
            Swal.fire({
              title: "Deleted successfully",
              icon: "success",

              customClass: {
                title: "custom-swal-title",
              },
            });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: "Error",
              icon: "error",

              customClass: {
                title: "custom-swal-title",
                text: "custom-swal-text",
              },
            });
          });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "There was an error while deleting",
        icon: "error",

        customClass: {
          title: "custom-swal-title",
        },
      });
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setSeo({ ...seo, [id]: value });
  };

  const handleseoSubmit = (e) => {
    e.preventDefault();
    setSeoSubmit(() => true);

    handleSubmit(e);
  };

  const handleEditDetails = (e) => {
    const defaultValue = "N/A";
    setEditMode(true);

    setEditPhone(uni.phone || defaultValue);
    setEditEmail(uni.email || defaultValue);
    setEditAddress(uni.address || defaultValue);
    setWebsiteUrl(uni.websiteURL || defaultValue);
  };
  const handleCancel = (e) => {
    setEditNameMode(false);
    setEditMode(false);
  };

  const handleAddDept = (e) => {
    setAddDept(e.target.checked);
  };

  const handleAddExamChange = (e) => {
    setAddEligibility(e.target.checked);
  };

  const handleEditNameMode = () => {
    setEditNameMode(true);
    seteditName(uni.instituteName);
  };

  const MAX_NAME_LENGTH = 70;

  const handleEditName = (e) => {
    const newName = e.target.textContent.trim();
    if (newName.length > MAX_NAME_LENGTH) {
      seteditName(newName.slice(0, MAX_NAME_LENGTH));
    } else {
      seteditName(newName);
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.textContent;
    // const validInput = input.replace(/[^\d, ]/g, "");
    setEditPhone(input.replace(/[^\d, ]/g, ""));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.textContent;

    if (validateEmail(newEmail)) {
      setEditEmail(newEmail);
      setError("");
    } else {
      setError("Please enter a valid email address.");
    }

    // setEditEmail(e.target.textContent);
  };
  const handleAddressChange = (e) => {
    setEditAddress(e.target.textContent);
  };

  const handleWebsiteChange = (e) => {
    setWebsiteUrl(e.target.textContent);
  };

  const handleEditLogo = () => {
    setImageType("instituteLogo");
    setIlShow(true);
  };
  const handleEditBanner = () => {
    setImageType("instituteBanner");
    setIlShow(true);
  };

  const handeEditedLogo = (e) => {
    if (imageType === "instituteLogo") {
      setEditLogoStat(true);
      setEditedLogo(e.target.files[0]);
    } else if (imageType === "instituteBanner") {
      setEditedBanner(e.target.files[0]);
    }
  };

  // Function to handle closing the modal
  const handleClose = () => {
    setShow(false);
    setcShow(false);
    setIlShow(false);
    setCoursePageshow(false);

    setError("");

    setCourseData({
      department: "",
      courseName: "",
      courseDurationYears: "",
      courseDurationMonths: "",
      feeCurrency: "",
      courseFee: "",
      courseFeeType: "",
      eligibility: [],
      aboutCourse: "",
    });
  };

  // Function to handle changes in the aboutInstitute field
  const handleContentChange = (e) => {
    if (e.target) {
      const { value } = e.target;
      setEditData((prevData) => ({ ...prevData, aboutInstitute: value }));
    } else {
      // Handle change event from Jodit Editor
      setEditData((prevData) => ({ ...prevData, aboutInstitute: e }));
    }
  };

  const handleAddExam = (e) => {
    setEligibilityValue(e.target.value);
  };

  const handleAddEligibilty = async (e) => {
    e.preventDefault();

    try {
      if (eligibilityValue && eligibilityValue.length > 0) {
        await axios
          .post(
            `${config.baseUrl}/Universities/postEligibilityCriteria`,
            {
              eligibilityValue,
            },
            {
              headers: {
                Authorization: credentials.token,
              },
            }
          )
          .then((res) => {
            setEligibilityValue("");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAlert = () => {
    Swal.fire({
      icon: "success",
      title: "Successfully updated data",
      customClass: {
        title: "custom-swal-title",
        text: "custom-swal-text",
        confirmButton: "custom-swal-button",
        cancelButton: "custom-swal-cancel-button",
      },
    });
    // setShowAlert(true);
  };

  const copyToClipboard = (email) => {
    navigator.clipboard.writeText(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let isValid = true;
      for (const key in courseData) {
        if (typeof courseData[key] === "string") {
          courseData[key] = courseData[key].trim();
        }
      }

      if (uni.instituteName === 0 || uni.phone === 0 || uni.address === 0) {
        console.log("Fields cannot be empty");
      }

      if (ilShow) {
        if (editedBanner) {
          console.log(editedBanner);
        }
        if (editedLogo) {
          console.log(editedLogo);
        }
      }

      if (cShow) {
        const {
          department,
          courseName,
          courseDurationYears,
          courseDurationMonths,
          aboutCourse,
          feeCurrency,
          courseFee,
          courseFeeType,
          eligibility,
          eligibilityAdditionalInfo,
        } = courseData;

        if (!department) {
          setError("Department is required");
          isValid = false;
        } else if (addDept && !department === 0) {
          setError("Department is required");
          isValid = false;
        } else if (!courseName) {
          setError("Course name is required");
          isValid = false;
        } else if (!courseDurationYears || !courseDurationMonths) {
          setError("Course duration is required");
          isValid = false;
        }
      }

      if (seoSubmit) {
        const { slug, title, metaDescription } = seo;

        if (title && title.length > 60) {
          setError(
            "Title tag should contain at least 50 characters and a maximum of 60."
          );
          isValid = false;
        } else if (metaDescription && metaDescription.length > 150) {
          setError(
            "Meta description should contain at least 120 characters and a maximum of 150."
          );
          isValid = false;
        }
      }

      if (isValid) {
        setSubmitState(true);

        if (editCourse) {
          await axios
            .put(
              `${config.baseUrl}/Universities/updateCourse/${editCourseData._id}`,
              courseData,
              {
                headers: {
                  Authorization: credentials.token,
                },
              }
            )
            .then((response) => {
              handleAlert();
              // Update local state with the edited course data
              setCourseData({
                department: "",
                courseName: "",
                courseDurationYears: "",
                courseDurationMonths: "",
                feeCurrency: "",
                courseFee: "",
                courseFeeType: "",
                eligibility: [],
                aboutCourse: "",
              });
              // Close the modal
              setcShow(false);
              setAddDept(false);
              setAddEligibility(false);
              setEditCourse(!editCourse);
              // setSubmitState(false);
              setError("");
            })
            .catch((err) => console.log(err));
        } else {
          await axios
            .put(
              `${config.baseUrl}/Universities/updateUniversity/${uni._id}`,
              {
                ...(editData.aboutInstitute && {
                  aboutInstitute: editData.aboutInstitute,
                }),
                ...(editName && { instituteName: editName }),
                ...(editPhone && { phone: editPhone }),
                ...(editEmail && { email: editEmail }),
                ...(editAddress && { address: editAddress }),
                ...(editWebsiteUrl && { websiteURL: editWebsiteUrl }),
                instituteLogo: editedLogo,
                instituteBanner: editedBanner,
                courseData,
                seo,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: credentials.token,
                },
              }
            )
            .then((response) => {
              handleAlert();
              // Update local state with the edited data
              setUni((prevUni) => ({
                ...prevUni,
                ...(editData.aboutInstitute && {
                  aboutInstitute: editData.aboutInstitute,
                }),
                ...(editName && { instituteName: editName }),
                ...(editPhone && { phone: editPhone }),
                ...(editEmail && { email: editEmail }),
                ...(editAddress && { address: editAddress }),
                ...(editWebsiteUrl && { websiteURL: editWebsiteUrl }),
                courseData,
                seo,
              }));

              // Close the modal
              setShow(false);
              setcShow(false);
              setAddDept(false);
              setAddEligibility(false);
              setIlShow(false);

              // Reset edit mode
              setEditMode(false);
              setEditNameMode(false);

              setError("");
              // setSubmitState(false);
            })
            .catch((err) => console.log(err));
        }
      }
    } catch (err) {
      console.log("Error updating university data:", err);
    }
  };

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/Universities/getuniInfo/${id}`)
      .then((res) => {
        setUni(res.data.data);
        if (res.data.data.instituteLogo) {
          setLogo(`${Url}/${res.data.data.instituteLogo.slice(2)}`);
        }

        if (res.data.data.seo) {
          setSeo(res.data.data.seo);
        }

        setCoursesInfo(res.data.courses);
        setDept(res.data.departments);
        setInstituteDepartments(res.data.instituteDepartments);
        setEligibilityExams(res.data.eligibility);
        if (res.data.data.instituteBanner) {
          setBanner(`${Url}/${res.data.data.instituteBanner.slice(2)}`);
        }
      })
      .catch((err) => {
        console.log("Error-----", err);
      });
  }, [
    courseData,
    Url,
    cShow,
    editNameMode,
    show,
    editMode,
    delStatus,
    eligibilityValue,
    editLogoStat,
    id,
  ]);

  useEffect(() => {
    if (editNameMode) {
      nameEditor.current.focus();
    }
    if (editMode) {
      detailsEditor.current.focus();
    }
  }, [editNameMode, editMode]);

  const memoizedDept = useMemo(() => dept, [dept]);
  const memoizedEligibilityExams = useMemo(
    () => eligibityExams,
    [eligibityExams]
  );
  const memoizedInstituteDepartments = useMemo(
    () => instituteDepartments,
    [instituteDepartments]
  );

  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-md-start flex-column  w-100 position-relative">
        <Helmet>
          {seo.title && seo.title.length > 0 ? (
            <title>{seo.title}</title>
          ) : (
            <title>{`Vidyauni | ${uni.instituteName} | ${uni.address}`}</title>
          )}
          <meta
            name="description"
            content={
              seo.metaDescription
                ? seo.metaDescription
                : "VidyaUni serves as an indispensable digital hub, offering comprehensive information on educational institutions across India, reminiscent of the renowned platform, Collegedunia. With its user-friendly interface and extensive database, Vidyavarsity caters to the needs of students and parents alike, facilitating informed decisions regarding academic pursuits. From prestigious universities to specialized colleges, the website provides detailed insights into admission procedures, course offerings, faculty profiles, and campus amenities. Its commitment to excellence and reliability makes Vidyavarsity a go-to resource for those navigating the labyrinth of higher education in India, empowering individuals to embark on their educational journeys with confidence and clarity."
            }
          />
        </Helmet>

        <img
          src={banner ? banner : test}
          alt="institute banner"
          className="instituteInfoBanner w-100 top-0 position-absolute"
        />
        {credentials.token && credentials.role === "admin" ? (
          <button
            className="bannerButton position-absolute border-0 bg-white p-2 m-3 rounded-2 end-0 top-0"
            onClick={handleEditBanner}
            title="Edit Institute Banner"
          >
            Edit Banner
          </button>
        ) : (
          ""
        )}

        <Link
          className="position-absolute start-0 top-0 IbackButton text-decoration-none m-3 rounded-2"
          to="/institutions"
        >
          {/* <p className="IbackButton fs-5 bg-white text-black">Back</p> */}
          <GoArrowLeft className="fs-3" />
        </Link>

        <div className="headingBlock position-relative d-flex align-items-center flex-column flex-md-row">
          <img
            src={logo ? logo : defaultLogo}
            alt="Institute, college, school default logo"
            className="instituteLogo bg-white rounded-3 position-relative"
          />
          {credentials.token && credentials.role === "admin" && (
            <FaPencilAlt
              className="position-absolute top-0 editImg"
              onClick={handleEditLogo}
              title="Edit Logo"
            />
          )}

          {editNameMode ? (
            <div className="position-relative d-flex headingEditBlock">
              <h5
                className="instituteHeading"
                contentEditable
                onBlur={handleEditName}
                ref={nameEditor}
              >
                {editName}
              </h5>
              <GiConfirmed
                onClick={handleSubmit}
                className=" text-white position-relative confirmNameBtn cursor-pointer ms-3 saveBtn"
                title="Save Changes"
              />
              <RxCrossCircled
                onClick={handleCancel}
                className=" text-white position-relative  ms-3 CancelBtn saveBtn"
                title="Cancel Changes"
              />
            </div>
          ) : (
            <h5 className="instituteHeading d-flex  position-relative mt-2">
              <span className="instituteHeadingw">{uni.instituteName}</span>

              {credentials.token && credentials.role === "admin" && (
                <span>
                  <FaPencilAlt
                    onClick={handleEditNameMode}
                    className="cursor-pointer ms-3 editName text-white fs-5 position-relative"
                    title="Edit Name"
                  />
                </span>
              )}
            </h5>
          )}
        </div>
      </div>

      <div className="mx-auto wrCollegeInfoPage position-relative pb-5">
        <div className="d-flex InfosubCard position-relative bg-white p-3">
          {editMode ? (
            <form onSubmit={handleSubmit}>
              <h6 className="infoSubHeading"></h6>
              <button
                className="fw-bold position-absolute end-0 bg-none cancelBtn top-0 m-3 border-0 rounded-3"
                onClick={handleCancel}
                title="Cancel Changes"
              >
                <RxCross2 className=" fs-3 " />
              </button>

              <p>
                <FaPhoneAlt className="me-2 iconCls" />
                <span
                  contentEditable
                  onBlur={handlePhoneChange}
                  className="bg-white"
                  ref={detailsEditor}
                >
                  {editPhone}
                </span>
              </p>
              <p>
                <IoLocationSharp className="me-2 iconCls" />
                <span
                  contentEditable
                  onBlur={handleAddressChange}
                  className="bg-white"
                >
                  {editAddress}
                </span>
              </p>
              <p>
                <MdEmail className="me-2 iconCls" />
                <span
                  contentEditable
                  onBlur={handleEmailChange}
                  className="bg-white"
                >
                  {editEmail}
                </span>
              </p>
              {error && error === "Please enter a valid email address." ? (
                <p className="text-danger">{error}</p>
              ) : (
                ""
              )}
              <p>
                <CgWebsite className="me-2 iconCls" />
                <span
                  contentEditable
                  onBlur={handleWebsiteChange}
                  className="bg-white"
                >
                  {editWebsiteUrl}
                </span>
              </p>
              <div>
                <button
                  type="submit"
                  className="submitBtn fw-bold me-5 rounded-3"
                  title="Save Changes"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </form>
          ) : (
            <div className="contactCard">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="position-relative">
                  <span className="infoSubHeading">Contact Information</span>

                  <span
                    className="ms-2 fs-5 infoDetails"
                    data-tooltip="Please provide contact details: up to 2 phone numbers, separated by comma
                    and 1 email address. Avoid including third-party websites if 
                    the institute's website is unavailable."
                  >
                    <GrCircleInformation className="infoCircle" />
                  </span>
                </h6>
                {credentials.token && credentials.role === "admin" && (
                  <button
                    className=" editBtn position-absolute end-0 "
                    onClick={handleEditDetails}
                    title="Edit"
                  >
                    <FaPencilAlt />
                  </button>
                )}
              </div>
              <div className="d-flex">
                <div className="editIcns">
                  <FaPhoneAlt className="me-2 iconCls " />
                </div>

                <p> {uni.phone} </p>
              </div>
              <div className="d-flex ">
                <div className="editIcns">
                  <IoLocationSharp className="me-2 iconCls " />
                </div>

                <p> {uni.address} </p>
              </div>
              <div className="d-flex">
                <div className="editIcns">
                  <MdEmail className="me-2 iconCls" />
                </div>

                <p> {uni.email} </p>
                {credentials.token && credentials.role === "admin" ? (
                  <div
                    onClick={() => copyToClipboard(uni.email)}
                    title="Copy to clipboard"
                    className="copytoClipboard"
                  >
                    <MdContentCopy className="iconCls ms-2" />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex">
                <div className="editIcns">
                  <CgWebsite className="me-2 iconCls" />
                </div>
                <p> {uni.websiteURL} </p>
                {credentials.token && credentials.role === "admin" ? (
                  <a
                    href={uni.websiteURL}
                    target="_blank"
                    rel="noreferrer"
                    className="ms-2"
                    title="Go to website"
                  >
                    <BsBoxArrowUpRight />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>

        <Tabs className="InfosubCard ">
          <TabList className="mb-0 ps-0">
            <Tab>About</Tab>
            <Tab>Courses</Tab>
            {credentials.token && <Tab>SEO</Tab>}
          </TabList>
          <TabPanel>
            <div className="p-3 position-relative bg-white">
              <div className="d-flex justify-content-between align-items-center">
                {/* <h6 className="infoSubHeading">About</h6> */}
                {credentials.token && credentials.role === "admin" && (
                  <button
                    onClick={handleAddContent}
                    className=" editBtn position-absolute end-0"
                    title="Edit"
                  >
                    <FaPencilAlt />
                  </button>
                )}
              </div>

              <div
                dangerouslySetInnerHTML={{ __html: uni.aboutInstitute }}
                className="pt-3"
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="position-relative bg-white">
              <div className="d-flex justify-content-between  p-3  align-items-center">
                {/* <h6 className="infoSubHeading mb-3">
                  Courses offered by {uni.instituteName}
                </h6> */}
                {credentials.token && credentials.role === "admin" && (
                  <button
                    onClick={handleAddCourse}
                    className="editBtn position-absolute end-0 "
                    title="Add Course"
                  >
                    <FaPlus />
                  </button>
                )}
              </div>
              {coursesInfo && coursesInfo.length === 0 ? (
                <div className="d-flex justify-content-center flex-column align-items-center p-3">
                  <p className="fw-bold">No courses available here</p>
                  {credentials.token && credentials.role === "admin" && (
                    <button
                      onClick={handleAddCourse}
                      title="Add Course"
                      className="border-0 p-2 rounded-3"
                    >
                      Add a course
                    </button>
                  )}
                </div>
              ) : (
                <div>
                  {memoizedInstituteDepartments.map((department) => (
                    <div key={department} className="allCourses pb-2">
                      <p className="fw-bold departmentName ms-2">
                        {department}
                      </p>
                      {coursesInfo && coursesInfo.length > 0 ? (
                        coursesInfo
                          .filter((course) => course.department === department)
                          .map((course, index) => (
                            <div
                              key={index}
                              className="d-flex justify-content-between coursecard position-relative border border-secoundary m-3 rounded-3"
                            >
                              <div onClick={() => handleCoursePage(course._id)}>
                                <p className="courseName mb-1">
                                  {course.courseName}
                                </p>

                                {course.aboutCourse &&
                                course.aboutCourse.length > 170 ? (
                                  <p className="about">
                                    {course.aboutCourse.slice(0, 167)}...
                                  </p>
                                ) : (
                                  <p className="about">
                                    {course.aboutCourse || ""}
                                  </p>
                                )}
                              </div>
                              {credentials.token &&
                                credentials.role === "admin" && (
                                  <div>
                                    <div className="me-4">
                                      <MdEdit
                                        title="Edit Course"
                                        onClick={() => {
                                          handleEditCourse(course._id);
                                        }}
                                      />
                                    </div>
                                    <div className="editBtn delHover position-absolute top-0 end-0">
                                      <MdDelete
                                        title="Delete Course"
                                        className="delHoverEffect"
                                        onClick={() => {
                                          handleDeleteCourse(course._id);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))
                      ) : (
                        <p>No courses available for this department.</p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </TabPanel>
          {credentials.token && (
            <TabPanel>
              <div className="position-relative bg-white">
                <form
                  className="d-flex flex-column p-2"
                  onSubmit={handleseoSubmit}
                >
                  <label
                    htmlFor="slug"
                    className="label ms-2 d-flex justify-content-between"
                  >
                    <p className="mb-0">
                      <span className="fw-bold">Slug</span>
                    </p>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Slug Text"
                    className="m-2 mb-4 p-2"
                    id="slug"
                    name="slug"
                    maxLength={60}
                    onChange={handleChange}
                    value={seo.slug}
                  />
                  <label
                    htmlFor="title"
                    className="label ms-2 d-flex justify-content-between"
                  >
                    <p className="mb-0">
                      <span className="fw-bold">Title</span>
                    </p>
                    <p className="mb-0">
                      <span className="fw-bold text-danger mx-2">
                        {seo.title.length}/60
                      </span>
                    </p>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter title tag"
                    className="m-2 mb-4 p-2"
                    id="title"
                    name="title"
                    maxLength={60}
                    onChange={handleChange}
                    value={seo.title}
                  />
                  <label
                    htmlFor="metaDescription"
                    className="label ms-2 d-flex justify-content-between"
                  >
                    <p className="mb-0">
                      <span className="fw-bold">Meta Description</span>
                    </p>
                    <p className="mb-0">
                      <span className="fw-bold mx-2 text-danger">
                        {seo.metaDescription.length}/150
                      </span>
                    </p>
                  </label>

                  <textarea
                    placeholder="Enter meta description"
                    className="m-2 p-2 mb-4 aboutCourse"
                    id="metaDescription"
                    maxLength={150}
                    onChange={handleChange}
                    value={seo.metaDescription}
                  />
                  <button className="submitBtn fw-bold rounded-3 mx-auto my-2">
                    Submit
                  </button>
                </form>
              </div>
            </TabPanel>
          )}
        </Tabs>

        <Modal show={cShow} onHide={handleClose} size="lg">
          <form className="d-flex flex-column justify-content-center mx-auto w-100 courseForm">
            <h4 className="text-center courseFormHeading">Edit Course</h4>
            <div className="d-flex w-100 m-2">
              {!addDept && (
                <fieldset className="d-flex flex-column w-50">
                  <label htmlFor="department" className="label">
                    Department
                  </label>
                  <select
                    name="department"
                    id="department"
                    onChange={handleCourseChange}
                    className="w-75 p-2"
                    value={courseData.department}
                  >
                    <option value="">Select Department</option>
                    {memoizedDept.map((dept, index) => (
                      <option key={index} value={dept}>
                        {dept}
                      </option>
                    ))}
                  </select>
                  {error && error === "Department is required" ? (
                    <p className="text-danger">{error}</p>
                  ) : (
                    ""
                  )}
                </fieldset>
              )}

              {addDept && (
                <fieldset className="d-flex flex-column w-50">
                  <label htmlFor="addDepartment" className="label">
                    Add Department
                  </label>
                  <input
                    type="text"
                    className="w-75 p-2"
                    id="addDepartment"
                    name="addDepartment"
                    onChange={handleCourseChange}
                  />
                  {error && error === "Department is required" ? (
                    <p className="text-danger">{error}</p>
                  ) : (
                    ""
                  )}
                </fieldset>
              )}

              <fieldset className="d-flex flex-column w-50">
                <label htmlFor="courseName" className="label">
                  Course Name
                </label>
                <input
                  type="text"
                  id="courseName"
                  name="courseName"
                  className="w-75 p-2"
                  onChange={handleCourseChange}
                  value={courseData.courseName}
                />
                {error && error === "Course name is required" ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  ""
                )}
              </fieldset>
            </div>

            <div className="mx-2">
              <input
                type="checkbox"
                id="addDeptCheckBox"
                name="addDeptCheckBox"
                checked={addDept}
                onChange={handleAddDept}
              />
              <label htmlFor="addDeptCheckBox" className="m-2 label">
                Add Department
              </label>
            </div>

            <div className="d-flex w-100">
              <div className="d-flex flex-column w-50 m-2">
                <label htmlFor="courseDurationYears" className="label">
                  Course Duration (YY-MM)
                </label>
                <div className="d-flex align-items-center w-50">
                  <select
                    name="courseDurationYears"
                    id="courseDurationYears"
                    className="w-50 p-2 me-1"
                    onChange={handleCourseChange}
                    value={courseData.courseDurationYears}
                  >
                    <option value="">Years</option>
                    {years.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  <select
                    name="courseDurationMonths"
                    id="courseDurationMonths"
                    className="w-50 p-2"
                    onChange={handleCourseChange}
                    value={courseData.courseDurationMonths}
                  >
                    <option value="">Months</option>
                    {months.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                </div>
                {error && error === "Course duration is required" ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="d-flex flex-column w-50 m-2">
                <label htmlFor="courseFee" className="label">
                  Course Fee
                </label>
                <div className="d-flex w-100">
                  <select
                    name="feeCurrency"
                    id="feeCurrency"
                    className="w-25 p-2"
                    onChange={handleCourseChange}
                    value={courseData.feeCurrency}
                  >
                    <option value="INR">INR - ₹</option>
                    <option value="CHF">CHF - CHF</option>
                    <option value="EUR">EUR - €</option>
                    <option value="NZD">NZD - NZD </option>
                    <option value="CAD">CAD - CAD </option>
                    <option value="USD">USD - $ </option>
                    <option value="AUD">AUD - AUD</option>
                  </select>
                  <div className="d-flex w-75">
                    <input
                      type="number"
                      id="courseFee"
                      name="courseFee"
                      className="w-100 p-2"
                      onChange={handleCourseChange}
                      value={courseData.courseFee}
                    />
                  </div>
                  <select
                    name="courseFeeType"
                    id="courseFeeType"
                    className="w-50 p-2"
                    onChange={handleCourseChange}
                    value={courseData.courseFeeType}
                  >
                    <option value="annually">Annually</option>
                    <option value="semester">Semester</option>
                  </select>
                </div>
              </div>
            </div>

            <fieldset>
              <label htmlFor="eligibility" className="label">
                Eligibility
              </label>
              <div className="d-flex flex-wrap p-2">
                {memoizedEligibilityExams.map((option) => (
                  <div key={option} className="d-flex align-items-center m-2">
                    <input
                      type="checkbox"
                      id={option}
                      name="eligibility"
                      value={option}
                      className="me-2"
                      onChange={handleCourseChange}
                      checked={courseData.eligibility.includes(option)}
                    />
                    <label htmlFor={option}>{option}</label>
                  </div>
                ))}
              </div>
            </fieldset>

            {addEligibility && (
              <fieldset>
                <label htmlFor="addEligibilty" className="label">
                  Add Eligibility
                </label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="addEligibilty"
                    id="addEligibilty"
                    className="p-2 w-100"
                    onChange={handleAddExam}
                    value={eligibilityValue}
                  />
                  <button
                    onClick={handleAddEligibilty}
                    className="border-0 fs-3 position-absolute addEligibilityBtn"
                  >
                    <LuPlus />
                  </button>
                </div>
              </fieldset>
            )}

            <fieldset>
              <input
                type="checkbox"
                id="addExamStatus"
                className="me-2"
                onChange={handleAddExamChange}
              />
              <label htmlFor="addExamStatus" className="label">
                Add Eligibility Exam
              </label>
            </fieldset>

            <fieldset>
              <label htmlFor="eligibilityAdditionalInfo" className="label">
                Eligibility Additional Info
              </label>
              <textarea
                name="eligibilityAdditionalInfo"
                id="eligibilityAdditionalInfo"
                className="w-100 p-2 eligibilityInfo"
                onChange={handleCourseChange}
                value={courseData.eligibilityAdditionalInfo}
              />
            </fieldset>

            <fieldset>
              <label htmlFor="aboutCourse" className="label">
                About Course
              </label>
              <textarea
                id="aboutCourse"
                name="aboutCourse"
                className="w-100 p-2 aboutCourse"
                onChange={handleCourseChange}
                value={courseData.aboutCourse}
              />
            </fieldset>
            <button
              className="submitBtn fw-bold rounded-3 mx-auto"
              onClick={handleSubmit}
              // disabled={submitState}
            >
              Submit
            </button>
          </form>
        </Modal>

        <Modal show={show} onHide={handleClose} size="xl">
          <form className="p-1 aboutForm d-flex flex-column justify-content-center ">
            <h3 htmlFor="about" className="infoSubHeading m-2">
              About
            </h3>
            <JoditEditor
              ref={editor}
              id="about"
              name="about"
              value={editData.aboutInstitute}
              onChange={handleContentChange}
              tabIndex={1}
              className="custom-editor"
            />
            <button
              className="submitBtn fw-bold rounded-3 mx-auto my-4"
              onClick={handleSubmit}
              title="Save Changes"
            >
              Submit
            </button>
          </form>
        </Modal>

        <Modal show={ilShow} onHide={handleClose} size="md">
          <form className="d-flex flex-column justify-content-center mx-auto w-100 courseForm">
            <fieldset>
              {imageType === "instituteLogo" ? (
                <label htmlFor="instituteLogo" className="ps-2 label">
                  Institute Logo
                </label>
              ) : (
                <labe htmlFor="instituteLogo" className="ps-2 label">
                  Institute Banner
                </labe>
              )}

              <input
                type="file"
                accept="images/*"
                onChange={handeEditedLogo}
                id={imageType}
                name={imageType}
                className="w-100 p-2"
              />
            </fieldset>
            <button
              className="submitBtn fw-bold rounded-3 mx-auto"
              onClick={handleSubmit}
              title="Save Changes"
            >
              Submit
            </button>
          </form>
        </Modal>

        <Modal show={coursePageShow} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title className="courseModalTitle">
              {courseData.courseName}
            </Modal.Title>
          </Modal.Header>
          <div className="aboutCourseModal p-3 p-md-4 ">
            <div className="d-lg-flex justify-content-between pe-md-5">
              <div className="d-flex flex-column mb-4">
                <div className="d-flex align-items-center fs-6 fw-bold text-secondary">
                  <BiSolidCategory className="me-2" />
                  <p> Department</p>
                </div>
                <p className="ms-4 courseText">{courseData.department} </p>
              </div>
              <div className="d-flex flex-column mb-4">
                <div className="d-flex align-items-center fs-6 fw-bold text-secondary">
                  <RiTimeFill className="me-2" />
                  <p>Course Duration</p>
                </div>
                <p className="ms-4 courseText">
                  {courseData.courseDurationYears !== 0 && (
                    <span className="m-0 me-1">
                      {courseData.courseDurationYears}&nbsp;
                      {courseData.courseDurationYears === 1 ? "year" : "years"}
                    </span>
                  )}
                  {courseData.courseDurationMonths !== 0 && (
                    <span className="m-0">
                      {courseData.courseDurationMonths}&nbsp;
                      {courseData.courseDurationMonths === 1
                        ? "month"
                        : "months"}
                    </span>
                  )}
                </p>
              </div>
              <div className="d-flex flex-column mb-4">
                <div className="d-flex align-items-center fs-6 fw-bold text-secondary">
                  <FaMoneyBills className="me-2" />
                  <p>Course Fee</p>
                </div>
                <p className="courseText ms-4">
                  {courseData.courseFee || "N/A"}
                  <span>&nbsp;{courseData.feeCurrency}</span>
                </p>
              </div>
            </div>
            <div className="mb-4">
              <div className="mb-4">
                <p className="fs-6 fw-bold text-secondary d-flex align-items-center">
                  <PiExamFill className="me-2" />
                  Eligibility
                </p>
                <div className="d-flex flex-wrap mb-3">
                  {courseData.eligibility.map((eligibility) => (
                    <p className="px-2 py-1 m-3 rounded-1 bg-body-tertiary courseText">
                      {eligibility}
                    </p>
                  ))}
                </div>
              </div>
              <div className="mb-4">
                <p className="fs-6 fw-bold text-secondary d-flex align-items-center">
                  <FaCircleInfo className="me-2" />
                  Eligibility Information
                </p>
                <p className="courseText ms-4">
                  {courseData.eligibilityAdditionalInfo}
                </p>
              </div>
              <div>
                <p className="fs-6 fw-bold text-secondary d-flex align-items-center">
                  <MdMenuBook className="me-2" /> About Course
                </p>
                <p className="courseText ms-4">{courseData.aboutCourse}</p>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default InstituteInfoPage;
