import "./landingPage.css";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import config from "../../../Boot/config.json";
import { useEffect, useState, useContext } from "react";
import Carousel from "react-bootstrap/Carousel";
import { AuthContext } from "../../../App";
import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { GrEdit } from "react-icons/gr";
import DummyImage from "../../../Assets/homePageBackground.jpg";

function LandingPage() {
  const [index, setIndex] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [show, setShow] = useState(false);
  const [del, setDel] = useState(false);
  const [error, setError] = useState("");
  const [editData, setEditData] = useState({});
  const { credentials } = useContext(AuthContext);

  const [CarouselData, setCarouselData] = useState({
    CarouselImage: null,
    CarouselTitle: "",
    CarouselDescription: "",
  });

  const [Data, setData] = useState([]);

  const handleClose = () => {
    setShow(false);
    setEditMode(null);
  };
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  //to add a new carousel
  const handleAddCarousel = () => {
    setCarouselData({
      CarouselImage: null,
      CarouselTitle: "",
      CarouselDescription: "",
    });
    setShow(true);
  };

  //input change function
  const handleCarouselInputchange = (e) => {
    const { id, value, files } = e.target;

    if (id === "CarouselImage") {
      setCarouselData((prevData) => ({
        ...prevData,
        [id]: files[0],
      }));
    }

    if (id === "CarouselTitle") {
      setCarouselData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
    if (id === "CarouselDescription") {
      setCarouselData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  //to add carousel
  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      if (!CarouselData.CarouselImage) {
        setError("Please select an image");
      } else if (!CarouselData.CarouselTitle) {
        setError("Please enter college name");
      } else if (!CarouselData.CarouselDescription) {
        setError("Please enter city name");
      } else {
        const myFormData = new FormData();
        myFormData.append("carouselImage", CarouselData.CarouselImage);
        myFormData.append("carouselTitle", CarouselData.CarouselTitle);
        myFormData.append(
          "carouselDescription",
          CarouselData.CarouselDescription
        );

        axios
          .post(`${config.baseUrl}/HomePage/addCarouselData`, myFormData, {
            headers: {
              Authorization: credentials.token,
            },
          })
          .then((res) => {
            setShow(false);
            alert("Carousel added successfully");
          })
          .catch((err) => {
            console.error("Error:", err);
          });

        setError("");
        setCarouselData({
          CarouselImage: null,
          CarouselTitle: "",
          CarouselDescription: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (id) => {
    const editData = Data.find((eachData) => {
      return id === eachData._id;
    });
    setEditData({
      id: editData._id,
      title: editData.carouselTitle,
      description: editData.carouselDescription,
    });
    setCarouselData({
      CarouselTitle: editData.carouselTitle,
      CarouselDescription: editData.carouselDescription,
    });
    setShow(true);
    setEditMode(true);
  };

  //to edit the carousel
  const handleEditSubmit = async (e, id) => {
    e.preventDefault();
    try {
      // Fetch the current carousel data from the server
      const myformData = new FormData();
      myformData.append("CarouselTitle", CarouselData.CarouselTitle);
      myformData.append(
        "CarouselDescription",
        CarouselData.CarouselDescription
      );

      // Conditionally append the new image only if it's present in CarouselData
      if (CarouselData.CarouselImage instanceof File) {
        myformData.append("CarouselImage", CarouselData.CarouselImage);
      }

      const response = await axios.put(
        `${config.baseUrl}/HomePage/updateSingleCarousel/${id}`,
        myformData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: credentials.token,
          },
        }
      );

      const currentCarouselData = response.data;

      // Update the current carousel data with the changes
      const updatedCarouselData = {
        ...currentCarouselData,
        CarouselImage: CarouselData.CarouselImage,
        CarouselTitle: CarouselData.CarouselTitle,
        CarouselDescription: CarouselData.CarouselDescription,
      };
      setCarouselData({
        CarouselImage: null,
        CarouselTitle: "",
        CarouselDescription: "",
      });

      setShow(false);
      setError("");
    } catch (error) {
      console.error("Error updating carousel:", error);
    }
  };

  //to delete the carousel
  const handleCarouselDelete = async (id) => {
    try {
      setDel(true);

      await axios
        .delete(`${config.baseUrl}/HomePage/deleteCarousel/${id}`, {
          headers: {
            Authorization: credentials.token,
          },
        })
        .then((res) => {
          setDel(false);
          alert("Delete Carousel");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/HomePage/getCarouselData`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [editData, CarouselData, show, del]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <form className="carouselForm d-flex flex-column justify-content-center mx-auto">
          <fieldset>
            <label htmlFor="CarouselImage"> Select Carousel Image</label>
            <input
              type="file"
              accept="image/*"
              id="CarouselImage"
              onChange={handleCarouselInputchange}
            />
            {error && error === "Please select an image" ? (
              <p className="text-danger">{error} </p>
            ) : (
              ""
            )}
          </fieldset>
          <fieldset>
            <label htmlFor="CarouselTitle"> Select Carousel Title</label>
            <input
              type="text"
              id="CarouselTitle"
              className="p-2"
              onChange={handleCarouselInputchange}
              value={CarouselData.CarouselTitle}
            />
            {error && error === "Please enter college name" ? (
              <p className="text-danger">{error} </p>
            ) : (
              ""
            )}
          </fieldset>
          <fieldset>
            <label htmlFor="CarouselDescription">
              Enter Carousel Description
            </label>
            <input
              type="text"
              className="p-2"
              id="CarouselDescription"
              onChange={handleCarouselInputchange}
              value={CarouselData.CarouselDescription}
            />
            {error && error === "Please enter city name" ? (
              <p className="text-danger">{error} </p>
            ) : (
              ""
            )}
          </fieldset>
          {!editMode && (
            <button
              type="Submit"
              className="submitBtn mx-auto fw-bold rounded-3"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
          {editMode && (
            <button
              type="Submit"
              className="submitBtn mx-auto fw-bold rounded-3"
              onClick={(e) => handleEditSubmit(e, editData.id)}
            >
              Submit
            </button>
          )}
        </form>
      </Modal>

      <div className="wr_home position-relative">
        {credentials.token && credentials.role === "admin" ? (
          <button
            className="addBtn rounded-circle d-none d-md-block position-absolute bottom-0 end-0 "
            onClick={handleAddCarousel}
            title="Add Carousel"
          >
            <FaPlus className="fs-5" />
          </button>
        ) : (
          ""
        )}

        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          className="bg-dark position-relative"
        >
          {credentials.token && credentials.role === "admin" ? (
            <Carousel.Item className="carouselImgWrapper w-100 bg-dark">
              <img src={DummyImage} alt="place holder" />
            </Carousel.Item>
          ) : (
            ""
          )}
          {Data.map((carousel, index) => {
            return (
              <Carousel.Item className="carouselImgWrapper" key={index}>
                <img
                  src={carousel.carouselImage}
                  alt="First slide"
                  className="carouselImg position-absolute top-0 start-0"
                />
                <Carousel.Caption>
                  <div className="d-flex justify-content-center">
                    <div>
                      <h3 className="carouselTitle">
                        {carousel.carouselTitle}
                      </h3>
                      <p className="">{carousel.carouselDescription}</p>
                    </div>
                  </div>
                </Carousel.Caption>
                {credentials.token && credentials.role === "admin" ? (
                  <div className="d-none d-md-flex flex-column position-absolute end-0 edtdelBtns">
                    <button
                      onClick={() => handleEdit(carousel._id)}
                      className="editBtn rounded-circle"
                      title="Edit Carousel"
                    >
                      <GrEdit className="fs-5" />
                    </button>
                    <button
                      onClick={() => handleCarouselDelete(carousel._id)}
                      className="delBtn rounded-circle"
                      title="Delete Carousel"
                    >
                      <MdDelete className="fs-5" />
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </>
  );
}

export default LandingPage;
