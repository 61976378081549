import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoginImage from "../../Assets/RegistrationBlockImage.jpg";
import "./loginPage.css";
import { AuthContext } from "../../App";
import config from "../../Boot/config.json";

function LoginPage() {
  const navigate = useNavigate();
  const { setCredentials } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "email") {
      setEmail(value);
    }
    if (id === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = () => {
    const formData = {
      email: email,
      password: password,
    };
    try {
      axios
        .post(`${config.baseUrl}/User/login`, formData)
        .then((response) => {
          const credentials = {
            token: response.data.token,
            userId: response.data.userId,
            role: response.data.userRole,
          };

          sessionStorage.setItem("token", JSON.stringify(credentials));
          // setToken(localStorage.getItem("token"));
          setCredentials(credentials);
          navigate("/");
        })
        .catch((err) => {
          setError("Incorrect email or password.");
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeySubmit = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="login_page">
      <div className="login_page_card">
        <img src={LoginImage} alt="admin login image" className="login_image" />
        <Form className="login_form">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            id="email"
            aria-describedby="passwordHelpBlock"
            onChange={handleChange}
          />
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            id="password"
            aria-describedby="passwordHelpBlock"
            onChange={handleChange}
            onKeyDown={handleKeySubmit}
          />
          {error ? <p className="text-danger">{error} </p> : ""}
          <Button onClick={handleSubmit}>Submit</Button>
        </Form>
      </div>
    </div>
  );
}

export default LoginPage;
