import "./institutionCards.css";
import { NavLink } from "react-router-dom";
// import schoolImg from "../../../Assets/schoolImg.jpg";
// import uniImg from "../../../Assets/uniImg.jpg";
import Modal from "react-bootstrap/Modal";
import { useContext, useEffect, useState } from "react";
import config from "../../../Boot/config.json";
import axios from "axios";
import { AuthContext } from "../../../App";
import { LuPlus } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function InstitutionsCards() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [delStatus, setDelStatus] = useState(false);
  const [formdata, setFormData] = useState({
    instituteImage: null,
    instituteTitle: "",
  });
  const navigate = useNavigate();

  const { credentials, setFilterData } = useContext(AuthContext);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    if (credentials) {
      if (credentials.token && credentials.role === "admin") {
        setAdmin(true);
      }
    }
  }, [credentials]);

  const handleClick = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleInputchange = (e) => {
    const { id, value, files } = e.target;
    if (id === "instituteImage") {
      setFormData((prev) => ({ ...prev, [id]: files[0] }));
    }
    if (id === "instituteTitle") {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios
        .delete(`${config.baseUrl}/HomePage/deleteInstitutionCard/${id}`, {
          headers: {
            Authorization: credentials.token,
          },
        })
        .then((res) => {
          alert("Delete Institution Card");
          setDelStatus(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      if (!formdata.instituteImage) {
        setError("Please select an image");
      } else if (!formdata.instituteTitle) {
        setError("Please enter institute name");
      } else {
        const myFormData = new FormData();
        myFormData.append("instituteImage", formdata.instituteImage);
        myFormData.append("instituteTitle", formdata.instituteTitle);

        axios
          .post(`${config.baseUrl}/HomePage/addInstituteCardFn`, myFormData, {
            headers: {
              Authorization: credentials.token,
            },
          })
          .then((res) => {
            alert("Added Successfully");
            setShow(false);
            setError("");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigate = (name) => {
    const filterData = {
      Colleges: {
        typeOfInstitutes: "College",
        collegeStatus: true,
        schoolStatus: false,
      },
      Universities: {
        typeOfInstitutes: "University",
        collegeStatus: false,
        schoolStatus: false,
      },
      Schools: {
        typeOfInstitutes: "School",
        collegeStatus: false,
        schoolStatus: true,
      },
    };

    const defaultFilterData = {
      states: [],
      countries: [],
      typeOfColleges: [],
      typeOfSchools: [],
    };

    setFilterData({
      ...defaultFilterData,
      ...filterData[name],
    });

    navigate(`/institutions`);
  };

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/HomePage/getInstituteCardFn`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [show, delStatus]);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <form
          onSubmit={handleSubmit}
          className="instituteCardForm d-flex flex-column justify-content-center mx-auto"
        >
          <fieldset>
            <label htmlFor="instituteImage">Institute Card Image</label>
            <input
              type="file"
              accept="image/*"
              id="instituteImage"
              name="instituteImage"
              onChange={handleInputchange}
            />
            {error && error === "Please select an image" ? (
              <p className="text-danger">{error} </p>
            ) : (
              ""
            )}
          </fieldset>
          <fieldset>
            <label htmlFor="instituteTitle">Institute Title</label>
            <input
              type="text"
              id="instituteTitle"
              onChange={handleInputchange}
            />
            {error && error === "Please enter institute name" ? (
              <p className="text-danger">{error} </p>
            ) : (
              ""
            )}
          </fieldset>

          <button
            className="submitBtn fw-bold rounded-3 mx-auto"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </form>
      </Modal>
      <div className="institution_section px-auto">
        <h2 className="my-4">Institutions</h2>
        <div className="wr_cards d-flex justify-content-center gap-3 flex-wrap mx-auto">
          {data.map((institute, index) => (
            <div
              key={index}
              onClick={() => handleNavigate(institute.instituteTitle)}
            >
              <div className="singleCard position-relative d-flex justify-content-center">
                {admin && (
                  <button
                    className="position-absolute delButton border-0 bottom-0 end-0 fs-2 text-dark"
                    onClick={(e) => {
                      handleDelete(institute._id);
                    }}
                  >
                    <MdDelete />
                  </button>
                )}

                <img
                  src={institute.instituteImage}
                  alt="institute"
                  className="institutionHImage"
                />
                <p className="position-absolute text-center instituteTitle fs-4">
                  {institute.instituteTitle}
                </p>
              </div>
            </div>
          ))}

          {admin && (
            <button
              onClick={handleClick}
              className="addInstituteButton d-none d-md-block"
            >
              <LuPlus className="fs-1" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default InstitutionsCards;
