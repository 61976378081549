import "./featuredCollege.css";
import { Modal } from "react-bootstrap";
import { AuthContext } from "../../../App";

import { useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../Boot/config.json";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";

function FeauredCollege() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [del, setDel] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [formData, setFormData] = useState({
    instituteLogo: null,
    instituteName: "",
  });
  const { credentials } = useContext(AuthContext);

  useEffect(() => {
    if (credentials) {
      if (credentials.token && credentials.role === "admin") {
        setAdmin(true);
      }
    }
  }, [credentials]);

  const handleClose = () => {
    setShow(false);
  };
  const handleClick = () => {
    setShow(true);
  };
  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "instituteLogo") {
      setFormData((prev) => ({ ...prev, [id]: files[0] }));
    }
    if (id === "instituteName") {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (!formData.instituteLogo) {
        setError("Select logo");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select a logo.",
        });
      } else if (!formData.instituteName) {
        setError("Enter College Name");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please enter the college name.",
        });
      } else {
        const headers = {
          "Content-Type": "multipart/form-data",
          Authorization: credentials.token,
        };

        axios
          .post(`${config.baseUrl}/HomePage/addFeaturedInstitute`, formData, {
            headers,
          })
          .then((res) => {
            setShow(false);
            setError("");
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Featured institute added successfully.",
            });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "There was an error adding the featured institute.",
            });
          });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error processing your request.",
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios
          .delete(`${config.baseUrl}/HomePage/delFeaturedInstitute/${id}`, {
            headers: {
              Authorization: credentials.token,
            },
          })
          .then((res) => {
            setDel(true);
            Swal.fire(
              "Deleted!",
              "Featured Institute has been deleted from the Home Page.",
              "success"
            );
          })
          .catch((err) => {
            console.log(err);
            Swal.fire(
              "Error!",
              "There was an error deleting the Featured Institute.",
              "error"
            );
          });
      }
    } catch (error) {
      console.log(error);
      Swal.fire(
        "Error!",
        "There was an error processing your request.",
        "error"
      );
    }
  };

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/HomePage/getFeaturedInstitute`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data, del]);

  return (
    <div className="wr_featuredCollege position-relative pb-5 ">
      <Modal show={show} onHide={handleClose}>
        <form
          className="featuredCollegesForm d-flex flex-column justify-content-center w-100 mx-auto"
          onSubmit={handleSubmit}
        >
          <fieldset>
            <label htmlFor="collegeLogo"> College Logo</label>
            <input
              type="file"
              accept="image/png, image/jpg"
              id="instituteLogo"
              onChange={handleChange}
            />
            {error && error === "Select logo" ? (
              <p className="text-danger">{error} </p>
            ) : (
              ""
            )}
          </fieldset>
          <fieldset>
            <input
              type="text"
              placeholder="Enter Institution/College Name"
              id="instituteName"
              onChange={handleChange}
            />
            {error && error === "Enter College Name" ? (
              <p className="text-danger">{error} </p>
            ) : (
              ""
            )}
          </fieldset>
          <button
            onClick={handleSubmit}
            className="submitBtn fw-bold rounded-3 mx-auto"
          >
            Submit
          </button>
        </form>
      </Modal>

      <div className="d-flex justify-content-center">
        <h2 className="text-white py-4">Featured Colleges</h2>
        {admin && (
          <button
            onClick={handleClick}
            className="position-absolute addBtn rounded-3 end-0 top-0"
          >
            Add
          </button>
        )}
      </div>

      <div className="d-flex justify-content-center flex-wrap">
        {data.map((institute, index) => (
          <div
            key={index}
            className="bg-white collegeCard position-relative d-flex flex-column align-items-center justify-content-center p-3"
          >
            {admin && (
              <MdDelete
                className="position-absolute bottom-0 end-0 fs-2"
                onClick={() => {
                  handleDelete(institute._id);
                }}
              />
            )}
            <img src={institute.instituteLogo} alt="instituteLogo" />
            <p className="text-center fw-medium">{institute.instituteName}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeauredCollege;
