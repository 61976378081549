import "./App.css";
import React, { createContext, useState, useEffect } from "react";
import NavbarComponent from "./Routes/Navbar";
import PathConfig from "./Routes/pathConfig";
import { FaArrowUp } from "react-icons/fa";

export const AuthContext = createContext();

const App = () => {
  const [credentials, setCredentials] = useState({
    token: "",
    role: "",
    userId: "",
  });
  const [ScrollTotopVisibile, setScrollToTopIsVisible] = useState(false);

  const [filterData, setFilterData] = useState({
    typeOfInstitutes: "",
    states: [],
    countries: [],
    typeOfColleges: [],
    typeOfSchools: [],
    collegeStatus: false,
    schoolStatus: false,
    // searchQuery: "",
  });

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    setCredentials({
      token: "",
      role: "",
      userId: "",
    });
  };

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleScroll = () => {
    if (window.scrollY > 30) {
      setScrollToTopIsVisible(true);
    } else {
      setScrollToTopIsVisible(false);
    }
  };
  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    if (storedToken) {
      setCredentials(JSON.parse(storedToken));
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logout = () => {
    return credentials.token ? (
      <div onClick={handleLogout}>Logout</div>
    ) : (
      <div style={{ display: "none" }}></div>
    );
  };

  return (
    <AuthContext.Provider
      value={{
        credentials,
        setCredentials,
        filterData,
        setFilterData,
        handleLogout,
        logout,
      }}
    >
      <>
        <NavbarComponent />
        <div className="routepages">
          <PathConfig />
          <button
            onClick={handleScrollToTop}
            className={`bottom-0 end-0 moveToTopButton justify-content-center position-fixed m-3 border-0 rounded-circle p-3 ${
              ScrollTotopVisibile ? "d-none d-md-flex" : "d-none"
            }`}
            title="Scroll to top"
          >
            <FaArrowUp className="text-dark" />
          </button>
          {/* <Footer /> */}
        </div>
      </>
    </AuthContext.Provider>
  );
};

export default App;
